<template>
  <div class="page_customer_list">
    <en-table-layout :tableData="tableData.data">
      <template slot="table-columns">
        <el-table-column label="编号" prop="member_id" />
        <el-table-column label="微信昵称" prop="nickname">
          <template slot-scope="scope">
            {{ unescape(scope.row.nickname) }}
          </template>
        </el-table-column>
        <el-table-column label="客户手机号" prop="phone" />
        <el-table-column label="是否关注店铺">
          <template slot-scope="scope">
            {{ scope.row.shop === "YES" ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="是否下过订单">
          <template slot-scope="scope">
            {{ scope.row.pay === "YES" ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="是否关注过店铺活动">
          <template slot-scope="scope">
            {{ scope.row.goods === "YES" ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="关注/收藏/下单时间">
          <template slot-scope="scope">
            {{ MixinUnixToDate("", "", scope.row.create_date) }}
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>
<script>
import * as API_Customer from "../../../api/customer";
export default {
  data() {
    return {
      tableData: "",
      params: {
        type: "",
        page_no: 1,
        page_size: 10,
      },
    };
  },
  mounted() {
    console.log(this.$route.params);
    if (this.$route.params.type === "follow") {
      this.params.type = "SHOP";
    } else if (this.$route.params.type === "place") {
      this.params.type = "PAY";
    } else if (this.$route.params.type === "follow_place") {
      this.params.type = "SHOP_NO_PAY";
    } else {
      this.params.type = "GOODS";
    }
    this.getMembersList();
  },
  methods: {
    /** 获取数据 */
    getMembersList() {
      API_Customer.membersList(this.params).then((res) => {
        this.tableData = res;
        console.log(res);
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.getMembersList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.getMembersList();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
